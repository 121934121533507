import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_OPERATORS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Operators",
      "search-by": "OperatorName,WindowsUserID,Facility,WarehouseCode",
      "ph-search-by": "Search by keywords"
    }
  }, [_c("text-field", {
    key: "Id",
    attrs: {
      "data-index": "id",
      "sorter": true,
      "title": "Operator Code"
    }
  }), _c("text-field", {
    key: "OperatorName",
    attrs: {
      "data-index": "operatorName",
      "sorter": true,
      "title": "Operator Name"
    }
  }), _c("text-field", {
    key: "WindowsUserID",
    attrs: {
      "data-index": "windowsUserID",
      "sorter": true,
      "title": "Windows UserID"
    }
  }), _c("text-field", {
    key: "Facility",
    attrs: {
      "data-index": "facility",
      "sorter": true,
      "title": "Facility"
    }
  }), _c("text-field", {
    key: "WarehouseCode",
    attrs: {
      "data-index": "warehouseCode",
      "sorter": true,
      "title": "Warehouse Code"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "Operators"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Operators = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.operators",
      "edit-route": "operators/:id",
      "create-route": "operators/create-operator",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("operators")], 1), _c("resource", {
    attrs: {
      "name": "grower.common.facilities",
      "api-url": _vm.apiUrl,
      "resource-id-name": "name"
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.ware-houses",
      "api-url": _vm.apiUrl
    }
  }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Operators
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_OPERATORS,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "operators",
          title: "Operators",
          path: "/watties-grower/operators"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
